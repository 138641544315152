import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import _, { last, sortBy } from 'lodash';
import { getShortLinkDestination } from '@unihelper/shared/portal-logic/find-short-link';

const getPreferredLanguage = (header: string | undefined): 'en' | 'da' => {
  if (!header) {
    return 'en';
  }
  try {
    // Accept-Language header is on the form "lang,lang2;q=0.9,lang3;q=0.8"
    const pairs = header.split(',').map((pair) => {
      const split = pair.split(';q=');
      // [language, preference score]
      return [split[0], Number(split[1]) || 1] as [string, number];
    });
    const mostPreferred = last(sortBy(pairs, '1'))![0];
    return mostPreferred === 'da' ? 'da' : 'en';
  } catch {
    return 'en';
  }
};

export const getServerSideProps: GetServerSideProps<{
  preferredLanguage: 'en' | 'da';
}> = async (context) => {
  const { shortLink } = context.query;
  const preferredLanguage = getPreferredLanguage(context.req.headers['accept-language']);

  // Only do the redirect if the host is our shortlink page.
  if (shortLink && _.isString(shortLink) && context.req.headers['host'] === 'svar.unihelper.dk') {
    const destination = await getShortLinkDestination(shortLink);
    if (destination) {
      return {
        redirect: {
          permanent: false,
          destination,
        },
      };
    }
  }

  return {
    props: { preferredLanguage },
  };
};

/**
 * Make a custom student-facing page here, so we don't need to share the shortlink 404 page
 * with our regular one.
 */
const StudentFacing404Page: React.FC<InferGetServerSidePropsType<typeof getServerSideProps>> = ({
  preferredLanguage,
}) => {
  return (
    <div className="m-8">
      <p>
        {preferredLanguage === 'da'
          ? 'Noget gik galt. Har du måske skrevet linket forkert?'
          : 'Something went wrong. Perhaps you mistyped the link you were given?'}
      </p>
    </div>
  );
};

export default StudentFacing404Page;
